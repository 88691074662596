import React from 'react'
import { Helmet } from 'react-helmet-async'

const HelmetHead = () => {
    return (
        <Helmet prioritizeSeoTags>
            <title>{process.env.REACT_APP_NAME ?? "Chat-App"}</title>
            <link rel="notImportant" href={process.env.REACT_APP_API_URL} />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <link rel="canonical" href={process.env.REACT_APP_API_URL} />
            <meta property="og:title" content="A very important title" />
        </Helmet>
    )
}

export default HelmetHead