import React, { useEffect } from 'react'
import ChatBox from '../../components/_main/Chat/ChatBox';
import { useSocket } from '../../context/SocketContext';

const ChatScreen = () => {
    const userId = localStorage.getItem("userId");
    const chatId = localStorage.getItem("chatId");
    const socket = useSocket();

    useEffect(() => {      
        socket.emit("join:room", chatId);
        socket.emit("user:live", userId);
    }, [socket, userId, chatId]);

    return (
        <div className="flex h-screen antialiased text-gray-800">
            <div className="flex flex-row h-full w-full overflow-x-hidden">
                <ChatBox />
            </div>
        </div>
    )
}

export default ChatScreen