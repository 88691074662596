import React, { createContext, useState, ReactNode, Dispatch, SetStateAction } from "react";

// Define types for the state values
type AuthState = [boolean, Dispatch<SetStateAction<boolean>>];
type UserState = [User | null, Dispatch<SetStateAction<User | null>>];
type ChatState = [Chat | null, Dispatch<SetStateAction<Chat | null>>];
type CurChatState = [ChatRoom | null, Dispatch<SetStateAction<ChatRoom | null>>];

// Define types for user and chat objects
interface User {
    _id: string;
    firstName: string,
    lastName: string,
    fullName: string,
    avatar: string,
    isOnline: string
};

interface Chat {
    // Define chat properties
};

// Define context type
type ContextType = {
    auth: AuthState;
    user: UserState;
    chat: ChatState;
    curChat: CurChatState;
    audio: any;
};

interface Member {
    _id: string;
    firstName: string;
    lastName: string;
    fullName: string;
    avatar: string;
    isOnline: boolean;
}

interface ChatRoom {
    _id: string;
    logo: string;
    members: Member[];
    createdAt: string;
    updatedAt: string;
    unreadCount: number;
}

// Create the context
export const GlobalContext = createContext<ContextType | undefined>(undefined);

// Define props type for GlobalProvider
type GlobalProviderProps = {
    children: ReactNode;
};

// Define GlobalProvider component
export const GlobalProvider = ({ children }: GlobalProviderProps): JSX.Element => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(localStorage.getItem("token") ? true : false);
    const [user, setUser] = useState<User | null>(() => {
        const storedUser = localStorage.getItem("user");
        return storedUser ? JSON.parse(storedUser) : null;
    });
    const [selectedChat, setSelectedChat] = useState<Chat | null>(localStorage.getItem("chatId") ? localStorage.getItem("chatId") : null);
    const [currChat, setCurrChat] = useState<ChatRoom | null>(null);
    const [currentAudio, setCurrentAudio] = useState<HTMLAudioElement | null>(null);
    return (
        <GlobalContext.Provider
            value={{
                auth: [isAuthenticated, setIsAuthenticated],
                user: [user, setUser],
                chat: [selectedChat, setSelectedChat],
                curChat: [currChat, setCurrChat],
                audio: [currentAudio, setCurrentAudio]
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
};
