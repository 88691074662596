import React, { useContext, useEffect, useState } from 'react';
import ListUser from '../Chat/ListUser';
import { conversationList, readMessages } from '../../../network/http';
import { useSocket } from '../../../context/SocketContext';
import { GlobalContext } from '../../../context/GlobalContext';

interface Member {
  _id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  avatar: string;
  isOnline: boolean;
}

interface ChatRoom {
  _id: string;
  logo: string;
  members: Member[];
  createdAt: string;
  updatedAt: string;
  messageUnreadCount: number;
  msg: any
}

interface Message {
  _id: string,
  groupId: string,
  messageText: string,
  createdBy: {
    _id: string,
    firstName: string,
    lastName: string,
    fullName: string,
    avatar: string
  }
  createdAt: string,
}

const SupportChatList = () => {

  const socket = useSocket();
  const [conversations, setConversations] = useState<ChatRoom[]>([]);
  const [activeChatId, setActiveChatId] = useState<string>('');
  const globalCtx = useContext<any>(GlobalContext);
  const chatSelected = globalCtx?.chat ?? [null, () => { }]
  const [selectedChat, setSelectedChat] = chatSelected;

  const [currChat, setCurrChat] = globalCtx?.curChat;

  const fetchConversations = async () => {
    const userId = localStorage.getItem('userId');
    await conversationList({ userId }).then((res) => {
      const newArray = res.data.sort((a: any, b: any) => {
        return b.unreadCount - a.unreadCount;
      });
      let newArray_1 = newArray.map((el: any) => {
        return { ...el, latestmesageDate: Date.now(), messageUnreadCount: el?.unreadCount }
      })
      setConversations(newArray_1);
      if (activeChatId !== undefined && activeChatId !== "") {
        setCurrChat(conversations.find((c) => c._id === activeChatId));
      }
    }).catch((err) => {
      console.log('Fetch conversations list --------- ', err);
    });
  }

  const handleMessageUpdate = (groupId: any) => {
    const newConversation = [...conversations];
    const updated_conv = newConversation?.map((el: any) => {
      if (el._id === groupId) {
        return { ...el, messageUnreadCount: 0 }
      }
      else {
        return el;
      }
    })

    const sorted_conversations = updated_conv.sort((a, b) => b.latestmesageDate - a.latestmesageDate);
    setConversations(sorted_conversations)
  }

  const handleActivateChat = async (chatId: string) => {
    console.log("Selected Chat Id ****** ", chatId)
    setActiveChatId(chatId);
    setCurrChat(conversations.find((c) => c._id === chatId));
    socket.emit("join:room", chatId);
    await readMessages({ groupId: chatId, userId: process.env.REACT_APP_SUPPORT_ID  })
      .then((res) => {
        handleMessageUpdate(chatId)
      }).catch(error => console.log(error))
  }

  useEffect(() => {
    fetchConversations();
  }, []);

  const handleGroupUpdate = (groupId: any, msg: any) => {
    const newConversation = [...conversations];
    const updated_conv = newConversation?.map((el: any) => {
      if (el._id === groupId) {
        return { ...el, latestmesageDate: Date.now(), messageUnreadCount: selectedChat !== groupId ? el?.messageUnreadCount + 1 : 0, msg: msg }
      }
      else {
        return el;
      }
    })

    const sorted_conversations = updated_conv.sort((a, b) => b.latestmesageDate - a.latestmesageDate);
    setConversations(sorted_conversations)
  }




  useEffect(() => {
    socket.on("chat:msg:msg:receive", (data: Message) => {
      handleGroupUpdate(data?.groupId, data)
    });

    socket.on("chat:newchat", (data: any) => {
      setConversations([{ ...data, latestmesageDate: Date.now(), messageUnreadCount: data?.unreadCount }, ...conversations])
    })
    return () => {
      socket.off("chat:msg:msg:receive");
      socket.off("chat:newchat")
    };
  }, [socket, conversations])

  return (
    <div className="flex flex-col h-5/6" key={"support_chats"}>
      <div className="flex flex-col space-y-1 mt-4 -mx-2 overflow-y-auto">
        {
          conversations.length > 0 && conversations.map((conversation, i) => {
            return <ListUser chatRoom={conversation} keyData={conversation._id} activeChatId={activeChatId} activateChat={handleActivateChat} key={i} />
          })
        }
      </div>
    </div>
  )
}

export default SupportChatList