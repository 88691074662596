const LogOutPage = () => {

 return (
  <div className="fixed left-0 right-0 top-0 bottom-0 flex justify-center items-center">
   <h1 className="text-lg text-indigo-600">Logged out successfully!</h1>
  </div>
 )
};


export default LogOutPage;