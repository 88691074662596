// SocketContext.tsx
import React, { createContext, useContext, ReactNode } from 'react';
import io, { Socket } from 'socket.io-client';

// Define the type for the children prop
type Props = {
    children: ReactNode;
};

const socketBaseUrl: string = process.env.REACT_APP_SOCKET_URL || ''; // Assuming the environment variable is a string

// Create a context for Socket.io
const SocketContext = createContext<Socket | undefined>(undefined);

// Custom hook to use the socket context
export const useSocket = (): Socket => {
    const socket = useContext(SocketContext);
    if (!socket) {
        throw new Error('useSocket must be used within a SocketProvider');
    }
    return socket;
};

// Socket provider component
export const SocketProvider = ({ children }: Props): JSX.Element => {
    const socket: Socket = io(socketBaseUrl, {
        autoConnect: true,
        reconnectionAttempts: 5,
        reconnectionDelay: 10000,
        transports: ['websocket'],
    }); // Replace with your Socket.io server URL

    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    );
};
