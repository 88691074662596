import React, { useContext, useEffect, useRef, useState } from 'react'
import { useSocket } from '../../../context/SocketContext'
import { messageList } from "../../../network/http"
import { GlobalContext } from '../../../context/GlobalContext';
import Message from './Message';
import moment from 'moment';
import Loader from '../../Loader';

interface Message {
  _id: string,
  groupId: string,
  messageText: string,
  createdBy: {
    _id: string,
    firstName: string,
    lastName: string,
    fullName: string,
    avatar: string
  }
  createdAt: string,
}

const MessageBox = () => {
  const globalCtx: any = useContext<any>(GlobalContext);
  const socket = useSocket();
  const containerRef = useRef<any>(null);
  const userId = localStorage.getItem("userId");
  const [messages, setMessages] = useState<Message[]>([]);
  const chatSelected = globalCtx?.chat ?? [null, () => { }]
  const [selectedChat, setSelectedChat] = chatSelected;
  const [loading, setLoading] = useState(true);

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  const loadMessages = async (chatId: string) => {
    setLoading(true)
    await messageList({ groupId: chatId }).then((res) => {
      setMessages([...res?.data])
      setLoading(false)
    }).catch(error => {
      console.log("error:", error);
      setLoading(false);
    });
  }

  useEffect(() => {
    loadMessages(selectedChat);
  }, [selectedChat])


  useEffect(() => {
    socket.on("chat:msg:receive", (data: Message) => {
      const isPresent = messages.some((obj) => obj._id === data._id)
      if (data.groupId === selectedChat && !isPresent) {
        console.log("receivedmsg1:", data)

        setMessages([...messages, data]);
      }
    });

    socket.on("chat:msg:sent", (data: Message) => {
      const isPresent = messages.some((obj) => obj._id === data._id)
      if (!isPresent) {
        setMessages([...messages, data]);
      }
    });

    scrollToBottom();

    return () => {
      socket.off("chat:msg:receive");
      socket.off("chat:msg:sent");
    };

  }, [socket, messages, selectedChat]);


  return (
    <>{loading ? <Loader /> :
      <>
        {/* {messages?.length <= 0 && <div className='h-full flex justify-center items-center'>
          <p>No messages</p>
        </div>} */}
        {messages?.length > 0 && <div className="grid grid-cols-12 gap-y-2 overflow-y-auto" ref={containerRef}>
          {
            messages?.length > 0 && messages.map((msg, index) => {
              const createdAt = moment(msg.createdAt).format("dd/NM/YYYY") < moment().format("dd/MM/YYYY") ? moment(msg.createdAt).format('MMM,DD YYYY HH:mm') : moment(msg.createdAt).format('HH:mm');
              if (msg.createdBy._id === userId) {
                return (
                  <div className="col-start-6 col-end-13 p-2 rounded-lg" key={`msg-${index}`}>
                    <div className="flex items-start justify-start flex-row-reverse">
                      <div className="flex items-center justify-center h-8 w-8 rounded-full bg-indigo-800 text-white flex-shrink-0">
                        {msg.createdBy.firstName.substring(0, 1)}
                      </div>
                      <div>
                        <div className="relative mr-3 text-base bg-indigo-100 p-2 shadow rounded-lg">
                          <Message msg={msg} createdBy={"self"} />
                        </div>
                        <div className='px-2 py-2 text-xs font-medium text-slate-600 text-right'>{createdAt}</div>
                      </div>
                    </div>
                  </div>
                )
              } else {
                return (
                  <div className="col-start-1 col-end-8 p-2 rounded-lg" key={`msg-${index}`}>
                    <div className="flex flex-row items-start">
                      <div className="flex items-center justify-center h-8 w-8 rounded-full bg-gray-800 text-white flex-shrink-0">
                        {msg.createdBy.firstName.substring(0, 1)}
                      </div>
                      <div>
                        <div className="relative ml-3 text-base bg-gray-200 p-2 shadow rounded-lg">
                          <Message msg={msg} createdBy={"self"} />
                        </div>
                        <div className='px-2 py-2 text-xs font-medium text-slate-600'>{createdAt}</div>
                      </div>
                    </div>
                  </div>
                )
              }
            })
          }
        </div>}
      </>}
    </>
  )
}

export default MessageBox