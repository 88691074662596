import React from 'react'
import ChatWindow from '../../components/_main/Support/ChatWindow'

const ChatScreen = () => {
    return (
        <div className="flex h-screen antialiased text-gray-800">
            <ChatWindow />
        </div>
    )
}

export default ChatScreen