import { useNavigate, useParams } from "react-router-dom";

const ErrorPage = () => {
 const navigate = useNavigate();
 const { userCode } = useParams();

 const handleNavigate = () => {
  navigate(`/${userCode}`)
 }

 return (<div className="h-screen w-full flex justify-center items-center ">
  <div className="flex justify-center items-center g-4 flex-col">
   <h1>Opppps... Something went wrong</h1>
   <button className="bg-indigo-600 text-white rounded-md px-10 py-2" onClick={handleNavigate}>Refresh</button>
  </div>
 </div>)
}
export default ErrorPage;