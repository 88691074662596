import React, { useContext, useEffect } from 'react'
import { GrChatOption } from 'react-icons/gr'
import SupportChatList from './SupportChatList'
import ChatBox from "../Chat/ChatBox";
import { useSocket } from '../../../context/SocketContext';
import { GlobalContext } from '../../../context/GlobalContext';
import Logo from "../../../assets/RivaMenuLogo.png";
import { useNavigate } from 'react-router-dom';


const ChatWindow = () => {
    const socket = useSocket();
    const globalCtx: any = useContext<any>(GlobalContext);
    const chatSelected = globalCtx?.chat ?? [null, () => { }]
    const [selectedChat, setSelectedChat] = chatSelected;
    const navigate = useNavigate()

    const handleLogout = () => {
        localStorage.clear();
        navigate("/logout")
    }


    return (
        <div className="flex flex-row h-full w-full overflow-x-hidden">
            <div className="flex flex-col py-2 px-4 w-64 bg-white flex-shrink-0">
                <div className="flex flex-col items-center justify-center h-20 w-full">
                    <img src={Logo} alt="logo" className='w-40 h-12' />
                    <div className="ml-2 font-bold text-2xl mt-[-10px]">Support</div>
                    <button className='border border-1 px-8 cursor-pointer bg-red-500 text-white rounded-lg font-medium py-1 mb-3 mt-1' onClick={handleLogout}>Log Out</button>
                </div>
                <SupportChatList />
            </div>
            {selectedChat && selectedChat !== "" && selectedChat !== null && selectedChat !== undefined ? <ChatBox /> : <div className='h-screen w-full flex justify-center items-center bg-gray-100'><h1 className='text-[18px]'>Welcome to RIVA support</h1></div>}
        </div>
    )
}

export default ChatWindow