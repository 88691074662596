import React, { useContext } from 'react'
import ChatInput from './ChatInput'
import MessagesContainer from './MessagesContainer'
import { GlobalContext } from '../../../context/GlobalContext';

const ChatBox = () => {

  const globalCtx: any = useContext<any>(GlobalContext);
  const [curChat, setCurrChat] = globalCtx.curChat;
  const [user, setUser] = globalCtx.user;
  const members = curChat?.members || [];

  const currentMember = members.find((m: any) => m._id !== user._id);

  

  return (
    <div className="flex flex-col flex-auto h-full px-0 py-0 bg-gray-400">
      <div className="flex flex-col flex-auto flex-shrink-0 bg-gray-200 h-full p-0">
        {
          members.length > 0 && (
            <div className="flex flex-row items-center bg-white p-2">
              {currentMember.fullName}
            </div>
          )
        }
        <MessagesContainer />
        <ChatInput />
      </div>
    </div>
  )
}

export default ChatBox