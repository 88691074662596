import { RiLoader4Fill } from "react-icons/ri";

const Loader = () => {
    return (
        <div className="h-screen w-full flex justify-center items-center">
            <div role="status">
                <RiLoader4Fill className="animate-spin text-sky-700 text-4xl" />
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}

export default Loader;