import { BsFiletypeTxt, BsFileWord, BsFilePdf, BsFiletypePpt, BsFiletypePsd, BsFiletypeXlsx, BsFiletypeXml, BsFiletypeDocx, BsFiletypeDoc, BsFiletypeExe } from "react-icons/bs";
import { IoMdDownload } from "react-icons/io";
import { FaFilePdf, FaFileWord, FaFileExcel, FaFilePowerpoint, FaFileCsv, FaFileAlt } from 'react-icons/fa';
import { IoVideocamOutline } from "react-icons/io5";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useContext, useState } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import "./audio.css";


const Message = ({ msg, createdBy }: { msg: any, createdBy: string }) => {
    const globalCtx: any = useContext<any>(GlobalContext);
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp', 'tiff', 'heic'];
    const videoExtensions = ['mp4', 'avi', 'mov', 'wmv', 'flv', 'mkv', 'webm', 'mpeg', 'mpg', '3gp'];
    const audioExtensions = ['mp3', 'wav', 'ogg', 'flac', 'aac', 'wma', 'm4a', 'ape', 'alac', 'aiff'];
    const documentExtention = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'txt', 'rtf', 'csv'];

    const [currentAudio, setCurrentAudio] = globalCtx.audio;

    const playAudio = (e: React.MouseEvent<HTMLAudioElement, MouseEvent>) => {
        e.preventDefault(); // Prevent default behavior of the audio element
        const audio = e.target as HTMLAudioElement;
        // Pause currently playing audio, if any
        if (currentAudio && currentAudio !== audio) {
            currentAudio.pause();
        }

        // Toggle play/pause for the selected audio
        if (!audio.paused) {
            audio.play();
            setCurrentAudio(audio);
        } else {
            audio.pause();
            setCurrentAudio(null);
        }
    };

    const getFileIcon = (extension: any) => {
        switch (extension.toLowerCase()) {
            case 'pdf':
                return <BsFilePdf fontSize={"30px"} />;
            case 'doc':
            case 'docx':
                return <FaFileWord fontSize={"30px"} />;
            case 'xls':
            case 'xlsx':
                return <FaFileExcel fontSize={"30px"} />;
            case 'ppt':
            case 'pptx':
                return <FaFilePowerpoint fontSize={"30px"} />;
            case 'txt':
            case 'rtf':
                return <FaFileAlt fontSize={"30px"} />;
            case 'csv':
                return <FaFileCsv fontSize={"30px"} />;
            default:
                return <FaFileAlt fontSize={"30px"} />;
        }
    };

    return (
        msg?.messageType === "text/plain" && !msg.file ?
            (
                <div className="flex gap-2">
                    <div className="font-medium">{msg?.messageText}</div>
                </div>
            )
            : (imageExtensions.includes(msg?.file?.ext.slice(1))) ? (
                <div>
                    <div className="flex gap-2">
                        <img src={msg?.file?.file} className="max-w-40 md:max-w-60 rounded-lg" alt="media" />
                    </div>
                    {msg?.messageText && <div className="font-medium">{msg?.messageText}</div>}
                </div>)
                : (videoExtensions.includes(msg?.file?.ext.slice(1))) ?
                    <div>
                        <div className="flex gap-2">
                            <IoVideocamOutline fontSize={"30px"} />
                            <p>{msg?.file?.org_name.length > 30 ? msg?.file?.org_name.substring(0, 27) + "..." : msg?.file?.org_name}</p>
                            <a href={msg?.file?.file} target="_blank" download>
                                <button><IoMdDownload fontSize={"20px"} /></button>
                            </a>
                        </div>
                        <div className="font-medium">{msg?.messageText}</div>
                    </div>
                    : (audioExtensions.includes(msg?.file?.ext.slice(1))) ? (
                        <div>
                            <div className="flex gap-2" >
                                <audio className="max-w-60 md:max-w-60 px-2 py-1" controls onPlay={playAudio} controlsList="nodownload" src={msg?.file?.file}
                                    id="audio_tag"
                                ></audio>
                            </div>
                            <div className="font-medium">{msg?.messageText}</div>
                        </div>
                    )
                        : (documentExtention.includes(msg?.file?.ext.slice(1))) ?
                            <div>
                                <div className="flex gap-2">
                                    {msg?.file?.ext === ".pdf" && <BsFilePdf fontSize={"30px"} />}
                                    {msg?.file?.ext === ".doc" && <FaFileWord fontSize={"30px"} />}
                                    {msg?.file?.ext === ".docs" && <FaFileWord fontSize={"30px"} />}
                                    {msg?.file?.ext === ".xls" && <FaFileExcel fontSize={"30px"} />}
                                    {msg?.file?.ext === ".xlsx" && <BsFiletypeXlsx fontSize={"30px"} />}
                                    {msg?.file?.ext === ".ppt" && <FaFilePowerpoint fontSize={"30px"} />}
                                    {msg?.file?.ext === ".pptx" && <FaFilePowerpoint fontSize={"30px"} />}
                                    {msg?.file?.ext === ".txt" && <BsFiletypeTxt fontSize={"30px"} />}
                                    {msg?.file?.ext === ".rtf" && <BsFiletypeTxt fontSize={"30px"} />}
                                    {msg?.file?.ext === ".csv" && <FaFileCsv fontSize={"30px"} />}
                                    <p>{msg?.file?.org_name.length > 30 ? msg?.file?.org_name.substring(0, 27) + "..." : msg?.file?.org_name}</p>
                                    <a href={msg?.file?.file} target="_blank" download>
                                        <button><IoMdDownload fontSize={"20px"} /></button>
                                    </a>
                                </div>
                                <div className="font-medium">{msg?.messageText}</div>
                            </div>
                            : <div>
                                <div className="flex gap-2">
                                    <BsFilePdf fontSize={"30px"} />
                                    <p>{msg?.file?.org_name.length > 30 ? msg?.file?.org_name.substring(0, 27) + "..." : msg?.file?.org_name}</p>
                                    <a href={msg?.file?.file} target="_blank" download>
                                        <button><IoMdDownload fontSize={"20px"} /></button>
                                    </a>
                                </div>
                                <div className="font-medium">{msg?.messageText}</div>
                            </div>
    )
}

export default Message;