import MessageBox from './MessageBox'

const MessagesContainer = () => {
    return (
        <div className="flex flex-col h-full overflow-x-auto">
            <div className="flex flex-col h-full">
                <MessageBox />
            </div>
        </div>
    )
}

export default MessagesContainer