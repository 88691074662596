import { resolve } from 'path';
import API from './apiconfig';

const resolveTimeOut = 2000;

interface ApiResponse {
    data: any; // Adjust the type of 'data' according to your API response structure
    // Add other properties if necessary
}

export const login = async (payload: any): Promise<any> => {
    await new Promise(resolve => setTimeout(resolve, resolveTimeOut));
    const response = await API.get(`/users/login/${payload.userId}`, payload) as ApiResponse; // Type assertion
    return response;
};

export const conversationList = async (payload: any) => {
    await new Promise(resolve => setTimeout(resolve, resolveTimeOut));
    const response = await API.get(`/groups/list/${payload.userId}`) as ApiResponse; // Type assertion
    return response;
};

export const conversationDetail = async (payload: any): Promise<any> => {
    await new Promise(resolve => setTimeout(resolve, resolveTimeOut));
    const response = await API.get(`/groups/detail/${payload.groupId}`) as ApiResponse; // Type assertion
    return response;
};

export const messageList = async (payload: any): Promise<any> => {
    await new Promise(resolve => setTimeout(resolve, resolveTimeOut));
    const response = await API.post(`/messages/list/${payload.groupId}`) as ApiResponse; // Type assertion
    return response;
};

export const unreadMessage = async (payload: any): Promise<any> => {
    await new Promise(resolve => setTimeout(resolve, resolveTimeOut));
    const response = await API.post(`/messages/readmessages`, payload) as ApiResponse;
    return response;
}

export const readMessages = async (payload: any): Promise<any> => {
    await new Promise(resolve => setTimeout(resolve, resolveTimeOut));
    const response = await API.post("/messages/readmessages", payload) as ApiResponse;   
    return response;
}

export const systemLogin = async (payload: any): Promise<any> => {
    await new Promise(resolve => setTimeout(resolve, resolveTimeOut));
    const response = await API.post(`/users/createchat`, payload) as ApiResponse;
    return response;
}