import React, { useContext, useEffect, useRef, useState } from 'react'
import { MdAttachFile, MdClose, MdOutlineStopCircle } from "react-icons/md";
import { MdMood } from "react-icons/md";
import { MdMicNone } from "react-icons/md";
import { MdOutlineSend } from "react-icons/md";
import { useSocket } from '../../../context/SocketContext';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { GlobalContext } from '../../../context/GlobalContext';
import ReactTextareaAutosize from 'react-textarea-autosize';
import { FaFileImage, FaFileVideo } from "react-icons/fa";
import { BsFiletypeExe } from "react-icons/bs";
import { useParams } from 'react-router-dom';
import { MdDeleteOutline } from "react-icons/md";

interface FileDetails {
  name: string;
  size: number;
  type: string;
}

const ChatInput = () => {

  const { lang } = useParams();
  const language = lang ?? "eng";
  const socket = useSocket();
  const globalCtx = useContext<any>(GlobalContext);

  const userId = localStorage.getItem('userId');
  const chatSelected = globalCtx?.chat ?? [null, () => { }];
  const [selectedChat, setSelectedChat] = chatSelected;

  const [messageText, setMessageText] = useState<string>('');
  const [pickerState, setPickerState] = useState<boolean>(false);
  const [showRecorder, setShowrecorder] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [fileDetails, setFileDetails] = useState<FileDetails | null>(null);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const maxFileSize = 10 * 1024 * 1024;

  const [audioBlob, setAudioBlob] = useState(null as Blob | null);
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [audioURL, setAudioURL] = useState<string>('');
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const [chunks, setChunks] = useState<Blob[]>([]);
  const [sendAudioMsg, setSendAudioMsg] = useState<boolean>(false);
  const [microphonePermission, setMicrophonePermission] = useState<boolean | null>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessageText(event.target.value);
  };


  //send text message, emoji message, file or media
  const handleSendMessage = () => {
    if (messageText !== '' && !file) {
      const data = {
        message: {
          groupId: selectedChat,
          messageText: messageText,
          messageType: "text/plain"
        },
        createdBy: userId
      }
      socket.emit("chat:msg:send", data);

    } else if (file) {
      if (file && file.size > maxFileSize) {
        alert('File size exceeds the maximum allowed size of 10 MB')
        return
      }
      const fileName = file.name;
      const fileType = file.type;
      const data = {
        message: {
          groupId: selectedChat,
          messageType: fileType,
          messageText: messageText
        },
        file: {
          filename: fileName,
          file: file
        },
        createdBy: userId
      }
      socket.emit("chat:msg:upload", data);
    }
    resetFormInputs();
  }

  //send recorded voice note 
  const sendAudio = (audioBlob: Blob) => {
    let data = {
      message: {
        groupId: selectedChat,
        messageType: 'audio/wav',
        messageText: ""
      },
      audio: {
        audio: audioBlob
      },
      createdBy: userId
    }
    socket.emit("audio_record", data);
    resetFormInputs();
  };

  const handleToggleEmojiPicker = () => {
    setPickerState(!pickerState);
  }

  const addEmoji = (emojiObject: any) => {
    setMessageText(messageText + emojiObject.native);
  }

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFile = e.target.files && e.target.files[0];
    if (newFile) {
      setFile(newFile);
      if (newFile) {
        setFile(newFile);
        const details: FileDetails = {
          name: newFile.name,
          size: newFile.size,
          type: newFile.type,
        };
        setFileDetails(details);
      }
    }
  }

  const handleRemoveMedia = () => {
    setFile(null)
    setFileDetails(null);
    if (inputFileRef.current) {
      inputFileRef.current.value = ''; // Clear input field value
    }
  }

  const resetFormInputs = () => {
    if (inputFileRef.current) {
      inputFileRef.current.value = ''; // Clear input field value
    }
    setFile(null)
    setFileDetails(null);
    setAudioURL('');
    setShowrecorder(false);
    setMessageText('');
    setPickerState(false);
  };

  useEffect(() => {
    let timer: any;

    if (isRecording) {
      timer = setInterval(() => {
        setTime(prevTime => prevTime + 1);
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [isRecording]);

  const formatTime = (timeInSeconds: number) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
  };

  useEffect(() => {
    if (isRecording) {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
          const recorder = new MediaRecorder(stream);
          recorder.ondataavailable = (event) => {
            setChunks(prevChunks => [...prevChunks, event.data]);
          };
          recorder.start();
          setMediaRecorder(recorder);
          setMediaStream(stream);
        })
        .catch(err => console.error('Error accessing microphone:', err));
    } else {
      if (mediaRecorder) {
        mediaRecorder.stop();
        clearRecording(); // Stop recording and clear the recording state
      }
    }
  }, [isRecording]);

  const handleStart = () => {
    setTime(0);
    setIsRunning(true);
    setIsRecording(true);
    setShowrecorder(true)
  };

  const clearRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      mediaRecorder.stream.getTracks().forEach(track => track.stop()); // Stop all tracks
      setChunks([]);
      setMediaRecorder(null);
    }
  };

  const handleCancelRecording = () => {
    setAudioURL('');
    clearRecording(); // Clear recording
    setTime(0);
    setIsRunning(false);
    setIsRecording(false);
    setShowrecorder(false);
  };

  const handleStop = () => {
    setIsRunning(false);
    setIsRecording(false);
    const url = URL.createObjectURL(new Blob(chunks, { type: 'audio/wav' }));
    setAudioURL(url);
  };

  const handleSendAudioMsg = () => {
    if (chunks.length > 0) {
      const audioBlob = new Blob(chunks, { type: 'audio/wav' });
      sendAudio(audioBlob);
    }
  }

  useEffect(() => {
    const checkMicrophonePermission = async () => {
      try {
        const permissionStatus = await navigator.permissions.query({ name: 'microphone' as PermissionName });
        setMicrophonePermission(permissionStatus.state === 'granted');
      } catch (error) {
        console.error('Error checking microphone permission:', error);
      }
    };
    checkMicrophonePermission();
    // Cleanup function
    return () => {
      setMicrophonePermission(null); // Reset permission status
    };
  }, []);

  useEffect(() => {
    socket.on("chat:msg:sent", (data) => {
      resetFormInputs();
    });
    return () => {
      socket.off("chat:msg:sent");
    }
  }, [socket]);

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      // Check if Control or Command key is pressed
      const ctrlKey = event.ctrlKey || event.metaKey;

      // Check if Enter key is pressed
      if (ctrlKey && event.key === 'Enter') {
        console.log("ctrl+enter")
        if (messageText !== "" || file !== null) {
          handleSendMessage();
        }
      }
    };

    // Add event listener to the document
    document.addEventListener('keydown', handleKeyDown);
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  
  const handlePlay = () => {
    const audio = new Audio(audioURL);
    audio.play(); // Play the audio element
  };
  
  return (
    <div>
      {
        pickerState && (
          <div style={{ position: 'absolute', bottom: '60px', right: '10px' }}>
            <Picker
              data={data}
              onEmojiSelect={(e: any) => addEmoji(e)}
              previewPosition='none'
              theme='dark'
              maxFrequentRows={0}
              onClickOutside={(e: any) => {
                if (!e.target.closest("#emojiPickerChat-id-custom")) {
                  setPickerState(!pickerState);
                }
              }}
            />
          </div>
        )
      }
      {
        (file && fileDetails) && (
          <div className='w-full bg-slate-200 p-2 rounded-b-none rounded-t-xl flex items-start relative'>
            <div className='flex flex-row item-center p-2'>
              {!fileDetails.type.startsWith('image') && !fileDetails.type.startsWith('video') && < div >
                <BsFiletypeExe fontSize={"3.0rem"} />
              </div>}
              {
                fileDetails.type.startsWith('video') && <FaFileVideo fontSize={"3.0rem"} />
              }
              {
                fileDetails.type.startsWith('image') && <FaFileImage fontSize={"3.0rem"} />
              }
              {!fileDetails.type.startsWith('video') && !fileDetails.type.startsWith('image') && (
                <div>
                  <div><strong>Name:</strong> {fileDetails.name}</div>
                  <div><strong>Size:</strong> {fileDetails.size} bytes</div>
                </div>
              )
              }
            </div>
            {fileDetails.type.startsWith('image') && (
              <div>
                <h2>Image Preview</h2>
                <img src={URL.createObjectURL(file as File)} alt="File Preview" className='w-20 h-20' />
              </div>
            )}
            {fileDetails.type.startsWith('video') && (
              <div>
                <h2>Video Preview</h2>
                <video width={"200px"} controls src={URL.createObjectURL(file as File)} />
              </div>
            )}
            <div className="absolute -top-1 -right-1 bg-white rounded-full p-0.5 shadow-lg" onClick={handleRemoveMedia}>
              <MdClose />
            </div>
          </div>
        )
      }

      <div className="flex flex-row items-end justify-stretch justify-top h-auto p-2 bg-white w-full" id="emojiPickerChat-id-custom">
        {
          !showRecorder && (
            <div>
              <button className="flex items-center justify-center p-2 rounded-xl bg-slate-200 text-gray-600 hover:bg-indigo-600 hover:text-white">
                <label htmlFor='file-input'>
                  <MdAttachFile fontSize={"1.2rem"} />
                  <input ref={inputFileRef} id="file-input" className='hidden'
                    // accept="image/*,.pdf,.txt,.mp4,.doc,.docx,.xls,.xlsx,.mp3"
                    type='file' multiple={false} onChange={handleFile} />
                </label>
              </button>
            </div>
          )
        }
        <div className="flex-grow mx-1">
          {
            showRecorder ? (
              <div className='flex flex-row items-center justify-end'>
                <button className='flex items-center justify-center border-2 border-rose-900 text-rose-900 hover:bg-rose-200 rounded-xl p-2 flex-shrink-0' onClick={handleCancelRecording}>
                  <MdDeleteOutline fontSize={"1.1rem"} />
                </button>
                <div className='p-2 py-1 mx-2 bg-slate-200 rounded-xl'>
                  {formatTime(time)}
                </div>
                {
                  isRecording && (
                    <button className='flex items-center justify-center border-2 border-slate-900 hover:bg-slate-200 rounded-xl text-salte-900 p-2 flex-shrink-0' onClick={handleStop}>
                      <MdOutlineStopCircle fontSize={"1.1rem"} />
                    </button>
                  )
                }              
                {
                  !isRecording && (
                    <button className='flex items-center justify-center bg-indigo-950 hover:bg-indigo-600 rounded-xl text-white p-2 flex-shrink-0' onClick={handleSendAudioMsg}>
                      <MdOutlineSend fontSize={"1.1rem"} />
                    </button>
                  )
                }
              </div>
            ) : (
              <div className="relative w-full" style={{ textAlign: 'right' }}>
                <ReactTextareaAutosize
                  minRows={1}
                  rows={1}
                  maxRows={4}
                  className="flex w-full border rounded-xl focus:outline-none focus:border-indigo-300 py-1 px-3 h-auto"
                  id="msg-input"
                  name="msg-input"
                  value={messageText}
                  onChange={handleInputChange}
                  dir={(language === "arb" || language === "kur") ? 'rtl' : 'ltr'}
                />
              </div>
            )
          }
        </div>
        {
          !showRecorder && !isRecording && (
            <div className="mx-1">
              <button className="flex items-center justify-center p-2 h-full rounded-xl bg-slate-200 text-gray-600 hover:bg-indigo-600 hover:text-white" onClick={handleStart} disabled={isRecording}>
                <MdMicNone fontSize={"1.2rem"} />
              </button>
            </div>
          )
        }
        {
          !showRecorder && (
            <>
              <div className="mx-1">
                <button className="flex items-center justify-center p-2 h-full rounded-xl bg-slate-200 text-gray-600 hover:bg-indigo-600 hover:text-white" onClick={handleToggleEmojiPicker}>
                  <MdMood fontSize={"1.2rem"} />
                </button>
              </div>
              <div className="mx-1">
                <button className="flex items-center justify-center bg-indigo-950 hover:bg-indigo-600 rounded-xl text-white p-2 flex-shrink-0" onClick={handleSendMessage}>
                  <MdOutlineSend fontSize={"1.2rem"} />
                </button>
              </div>
            </>
          )
        }
      </div>
    </div>
  )
}

export default ChatInput