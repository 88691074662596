import React, { useEffect } from 'react';
import HelmetHead from './components/HelmetHead';
import { Routes, Route } from 'react-router-dom';
import Login from './screens/Login';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserChatScreen from './screens/User/ChatScreen';
import SupportChatScreen from './screens/Support/ChatScreen';
import ErrorPage from './screens/ErrorPage';
import LogOutPage from './LoggedOutPage';

function App() {


  return (
    <div className={"App bg-white dark:bg-black"}>
      <HelmetHead />
      <Routes>
        <Route path="/:userCode/:lang?" index element={<Login />} />
        <Route path="/user/chat/:lang?" index element={<UserChatScreen />} />
        <Route path="/support/chat/:lang?" index element={<SupportChatScreen />} />
        <Route path='/error/:userCode' index element={<ErrorPage />} />
        <Route path='/logout' index element={<LogOutPage />} />
      </Routes>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        theme="dark"
      />
    </div>
  );
}

export default App;
